import gsap, { Power1, Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Ease } from '../_app/cuchillo/utils/Ease';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Page from '../_app/cuchillo/pages/Page';

import Timeline from '../3D/Timeline';
import States from '../3D/States';
import Header from '../layout/Header';
import Loader from '../layout/Loader';

export default class Project extends Page {
  _projectID;
  _backBtn;
  _title;
  _titleWords;
  _items;
  _itemsWords;
  _holder;

  constructor() {
    super();

    this.container.style.opacity = 0;
    this._projectID = this.container.dataset.id;

    this._backBtn = GetBy.id('timeline-back');
    this._call = () => {
      this.back();
    };

    const container = GetBy.id('portada');
    this._title = GetBy.class('__title', container)[0];
    this._titleWords = new SplitText(this._title, {
      type: 'lines',
      linesClass: 'lines-child'
    }).lines;
    new SplitText(this._title, {
      type: 'lines',
      linesClass: 'lines-parent'
    });
    this._titleWords.map((w) => {
      w.style.transformOrigin = 'top left';
    });

    this._items = GetBy.class('__item', container);
    this._itemsWords = new SplitText(this._items, {
      type: 'lines',
      linesClass: 'lines-child'
    }).lines;
    new SplitText(this._items, {
      type: 'lines',
      linesClass: 'lines-parent'
    });
  }

  show__effect () {
    super.show__effect();

    gsap.to(this.container, {
      opacity: 1,
      ease: Power1.easeOut,
      duration: .3,
      onComplete: () => {
        this.afterShow();
      }
    })
  }

  beforeShow () {
    Header.activate(true);

    this._backBtn.addEventListener(Basics.clickEvent, this._call);

    if (this.isFirstTime) {
      Timeline.init(States.detail);
      Timeline.start();
      Timeline.pause(this._projectID);
      Timeline.activateProjects();
    }

    gsap.set(this._titleWords, {
      y: '105%',
      rotationZ: `${10}deg`
    });
    gsap.set(this._itemsWords, {
      y: '105%',
    });
    gsap.set(this._backBtn, {
      y: '150%'
    });

    this._holder = GetBy.selector('#Main .__holder')[0];
    gsap.set(this._holder.parentNode, {
      left: '100vw'
    });

    if (!isMobile) {
      Scroll.init(Scroll.AXIS_X, {
        smooth: !isMobile,
        multiplicator: .8,
        container: document.body,
        element: window
      });
    }
  }

  afterShow () {
    super.afterShow();

    const cb = () => {
      Header.show();

      gsap.to(this._holder.parentNode, {
        left: 0,
        ease: Ease.EASE_CUCHILLO_OUT,
        duration: 1.7,
      });
      gsap.to(this._titleWords, {
        y: 0,
        rotateZ: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        duration: 1.8,
        delay: .3
      });
      gsap.to(this._itemsWords, {
        y: 0,
        rotateZ: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        duration: 1.5,
        delay: .8,
        onComplete: () => {
          if (!isMobile) Scroll.start();
        }
      });
      gsap.to(this._backBtn, {
        y: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        duration: 1.5,
        delay: .8,
      });
    }

    if (this.isFirstTime) Loader.start(cb);
    else cb();
  }

  hide__effect () {
    gsap.to(this.container, {
      opacity: 0,
      ease: Power1.easeOut,
      duration: .5,
      delay: 1,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  beforeHide () {
    super.beforeHide();

    Header.activate(false);

    gsap.to([...this._titleWords, ...this._itemsWords], {
      y: '105%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5
    });
    gsap.to(this._backBtn, {
      y: '150%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5
    });

    const images = GetBy.selector('img', this._holder);
    gsap.to(images, {
      opacity: 0,
      duration: .8,
      ease: Power3.easeOut
    });
  }

  afterHide () {
    this._backBtn.removeEventListener(Basics.clickEvent, this._call);

    if (!isMobile) Scroll.dispose();
    super.afterHide();

    Timeline.play(this._projectID);
  }

  back () {
    ControllerPage.changePage('/', 'push');
  }
}

ControllerPage._addPage('project', Project);
