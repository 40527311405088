import gsap, { Power3 } from 'gsap';

import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__ProjectImage extends VScroll_Item {
  _img;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._img = GetBy.selector('img', __link)[0];
    gsap.set(this._img, {
      scale: 1.2
    });

    // Scroll.addBullet(this.id);

    this.onShow = () => {
      gsap.to(this._img, {
        scale: 1,
        duration: 1.4,
        ease: Power3.easeOut
      });
    };
    this.onHide = () => {};
    this.onMove = () => {}
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose () {
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('project-img', ScrollItem__ProjectImage);
