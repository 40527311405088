import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import Header from '../layout/Header';
import Loader from '../layout/Loader';
// import Default from './Default';

export default class NotFound extends Page {
  id;
  isWrapAutoRemove = true;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();
  }

  beforeShow () {
    Loader.forceHide();
  }

  show__effect () {
    super.show__effect();
    this.afterShow();
  }

  afterShow () {
    Header.show();
    document.body.classList.add("body-not-found");
  }

  beforeHide () {}

  afterHide () {
    Header.hide();
    document.body.classList.remove("body-not-found");
    super.afterHide();
  }
}

ControllerPage._addPage("not-found", NotFound)
