import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Ease } from '../_app/cuchillo/utils/Ease';
import { GetBy } from '../_app/cuchillo/core/Element';
import Timeline from '../3D/Timeline';
import States from '../3D/States';

export default class Titles {
  _items;
  initialized = false;

  static init () {
    this._items = {};

    const titles = Array.from(GetBy.selector('#project-titles .__wrapper')); 
    titles.map(block => {
      const { id } = block.dataset;

      const title = GetBy.class('__title', block)[0];
      const titleWords = new SplitText(title, {
        type: 'lines',
        linesClass: 'lines-child'
      }).lines;
      new SplitText(title, {
        type: 'lines',
        linesClass: 'lines-parent'
      });
      titleWords.map((w) => {
        w.style.transformOrigin = 'top left';
      });

      const items = GetBy.class('__item', block);
      const itemsWords = new SplitText(items, {
        type: 'lines',
        linesClass: 'lines-child'
      }).lines;
      new SplitText(items, {
        type: 'lines',
        linesClass: 'lines-parent'
      });

      gsap.set(titleWords, {
        y: '105%',
        rotationZ: `${10}deg`
      });
      gsap.set(itemsWords, {
        y: '105%',
      });

      this._items[id] = {
        titleWords,
        itemsWords
      };
    });

    this.initialized = true;
  }

  static showTitle(id) {
    if (!this.initialized) return;
    if (Timeline.state !== States.scroll) return;

    gsap.killTweensOf(this._items[id].titleWords);
    gsap.killTweensOf(this._items[id].itemsWords);
    gsap.to(this._items[id].titleWords, {
      y: 0,
      rotateZ: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1,
      delay: .2
    });
    gsap.to(this._items[id].itemsWords, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 0.8,
      delay: .4
    });
  }

  static hideTitle(id) {
    if (!this.initialized || !this._items[id]) return;
    gsap.killTweensOf(this._items[id].titleWords);
    gsap.killTweensOf(this._items[id].itemsWords);
    gsap.to([...this._items[id].titleWords, ...this._items[id].itemsWords], {
      y: '105%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .3
    });
  }

  static resetTitles () {
    Object.keys(this._items).map(id => {
      Titles.hideTitle(id);
    });
  }
}
