import * as THREE from 'three';
import gsap, { Power2 } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Ease } from '../_app/cuchillo/utils/Ease';

export default class Loader {
  _textureLoader;
  _loader;
  _progress;
  _sup;
  _supWidth;
  _text;
  _textHeight;
  _assets;
  _circleBase;
  _circleTop;
  _logo;
  static TOTAL_TO_LOAD = 0;
  static TOTAL_LOADED = 0;
  static FAKE_LOADER = 0;
  static FAKE_PROGRESS = 0;

  static init () {
    this._textureLoader = new THREE.TextureLoader();
    this._assets = [];

    this._loader = GetBy.id('page-loader');
    this._loader.style.opacity = 1;

    this._progress = GetBy.class('__progress', this._loader)[0];
    this._sup = GetBy.class('__sup', this._loader)[0];
    this._logo = GetBy.class('__logo', this._loader)[0];
    this._text = GetBy.class('__text', this._loader)[0];
    this._textHeight = this._text.parentNode.getBoundingClientRect().height;
    this._text.parentNode.style.height = '0';
    this._circleBase = GetBy.id('circle-base');
    this._circleTop = GetBy.id('circle-top');

    Loader.TOTAL_TO_LOAD = 10;

    gsap.set(this._progress, { y: '105%' });
    gsap.set(this._sup, { y: '50%' });
    gsap.set(this._text, { y: '105%' });
    gsap.set(this._circleBase, { drawSVG: '0%' });
    gsap.set(this._circleTop, { drawSVG: '0%' });

    gsap.to(this._circleBase, {
      drawSVG: '100%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1,
      delay: .4
    });
  }

  static start (cb) {
    this._callback = cb;

    gsap.to(this._logo, {
      y: '105%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5,
      delay: 1.2
    });
    gsap.to(this._progress, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1.2,
      delay: 1.8
    });
    gsap.to(this._sup, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .7,
      delay: 2.3,
      onComplete: () => {
        Loader.loadAssets();
      }
    });
  }

  static loadImage (src, callback = () => { }) {
    this._assets.push({
      src,
      callback
    });
  }

  static loadAssets () {
    gsap.ticker.add(Loader.loop);

    this._assets.map(({ src, callback }) => {
      const texture = this._textureLoader.load(
        src,
        () => {
          Loader.TOTAL_LOADED++;
          callback(texture);
        },
        undefined,
        // onError callback
        function (err) {
          console.error('An error happened.', err);
        }
      );
    });
  }

  static update () {
    this._progress.innerHTML = Loader.FAKE_LOADER;

    gsap.killTweensOf(this._circleTop);
    gsap.to(this._circleTop, {
      duration: .1,
      drawSVG: `${Loader.FAKE_PROGRESS}%`,
      ease: Power2.easeOut
    });
  }

  static loop () {
    let progress = (Loader.TOTAL_LOADED / Loader.TOTAL_TO_LOAD) * 360;

    if (Loader.FAKE_LOADER < progress) {
      Loader.FAKE_LOADER = Math.min(360, Loader.FAKE_LOADER + 7);
      Loader.FAKE_PROGRESS = Loader.FAKE_LOADER / 3.6;

      Loader.update();

      if (Loader.FAKE_LOADER === 360) {
        gsap.ticker.remove(Loader.loop);
        Loader.onComplete();
      }
    }
  }

  static onComplete () {
    this._progress.innerHTML = 360;

    gsap.killTweensOf(this._circleTop);
    gsap.to(this._circleTop, {
      duration: .3,
      drawSVG: '100%',
      ease: Power2.easeOut,
      onComplete: () => {
        Loader.hide();
      }
    });
  }

  static hide () {
    let delay = .5;

    // Animates Arquitecture
    gsap.to(this._text.parentNode, {
      height: this._textHeight,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1.2,
      delay
    });
    delay += .3;

    gsap.to(this._text, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1.5,
      delay
    });
    delay += 2.4;

    gsap.to(this._text, {
      y: '105%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5,
      delay
    });
    gsap.to(this._sup, {
      y: '50%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5,
      delay
    });
    gsap.to(this._progress, {
      y: '105%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .5,
      delay
    });

    gsap.to(this._circleTop, {
      duration: .5,
      delay,
      drawSVG: '0%',
      ease: Ease.EASE_CUCHILLO_IN_OUT
    });
    gsap.to(this._circleBase, {
      duration: .5,
      delay,
      drawSVG: '0%',
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      onComplete: () => {
        this._callback();
      }
    });
    delay += .8;

    gsap.to(this._loader, {
      opacity: 0,
      duration: .5,
      delay,
      ease: Power2.easeOut,
      onComplete: () => {
        this._loader.style.display = 'none';
      }
    });
  }

  static forceHide () {
    this._loader.style.display = 'none';
  }
}
