export const IMAGE_FRAGMENT = `
    #define PI 3.1415926538

    uniform float imageScale;
    uniform float aspectRatio;
    uniform float opacity;
    uniform sampler2D texture1;
    uniform vec2 resolution;
    // uniform vec3 color;
    uniform float hover;
    // uniform float grain;
    uniform float bw;
    varying vec2 vUv;
    float scaleCenter = 0.5;

    float random(vec2 p) {
        vec2 K1 = vec2(
            23.14069263277926, // e^pi (Gelfond's constant)
            2.665144142690225 // 2^sqrt(2) (Gelfond-Schneider constant)
        );
        return fract(cos(dot(p, K1)) * 12345.6789);
    }

    vec3 grayscale (vec3 color, float bw) {
        float gray = 0.21 * color.r + 0.71 * color.g + 0.07 * color.b;
        return color.rgb * (1.0 - bw) + (gray * bw);
    }

    void main() {
        // Applies aspect ratio to plane
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        // Gets new centered coords for the texture and scales the image
        // to the plane size with a ratio for scroll (when in detail page) and a ratio for animation
        vec2 newUVScale = (newUV - scaleCenter) * imageScale + scaleCenter;
        // vec2 newUVScale = (newUV - scaleCenter) + scaleCenter;

        // Gets texture pixel color
        vec4 textureColor = texture2D(texture1, newUVScale);

        // Mixes color for hover
        // vec3 mixColor = mix(textureColor.rgb, color, hover);
        vec3 mixColor = textureColor.rgb;

        // Adds grain to photo
        // vec2 uvRandom = vUv;
        // uvRandom.y *= random(vec2(uvRandom.y, grain));
        // mixColor.rgb += random(uvRandom) * 0.3;

        // gl_FragColor = vec4(mixColor, textureColor.a * opacity);

        gl_FragColor = vec4(grayscale(mixColor.rgb, bw), textureColor.a * opacity);
    }
`;
