import { CachedCurve } from '@jocabola/math';
import {
  BufferGeometry,
  CatmullRomCurve3,
  Group,
  Line,
  LineBasicMaterial,
  Points,
  PointsMaterial,
  Vector3
} from 'three';
import { isDebug } from '../_app/cuchillo/core/Basics';
// import { generateRandomCurve, MODES } from './PathUtils';

import { Maths } from '../_app/cuchillo/utils/Maths';

// export type NFTCurve = CachedCurve | CatmullRomCurve3;

/**
 * Generate a series of motion paths where
 */
export const PATH_DUMMIES = new Group();
PATH_DUMMIES.visible = true;

export const LINE_MAT = new LineBasicMaterial({
  color: 0x666666,
  visible: true
});
export const POINTS_MAT = new PointsMaterial({
  color: 0xcc0000,
  size: 8,
  sizeAttenuation: false,
  visible: true
});

const N_POINTS = 10000;
const dummyPts = [];

export default class Path {
  path;
  radius = 2400;

  constructor() {
    //  Creates curve
    const pts = [];

    pts.push(new Vector3(this.xFromDeg(90), 0, this.yFromDeg(90)));
    pts.push(new Vector3(this.xFromDeg(75), 0, this.yFromDeg(75)));
    pts.push(new Vector3(this.xFromDeg(60), 0, this.yFromDeg(60)));
    pts.push(new Vector3(this.xFromDeg(45), 0, this.yFromDeg(45)));
    pts.push(new Vector3(this.xFromDeg(30), 0, this.yFromDeg(30)));
    pts.push(new Vector3(this.xFromDeg(15), 0, this.yFromDeg(15)));

    pts.push(new Vector3(this.xFromDeg(0), 0, 0));
    pts.push(new Vector3(this.xFromDeg(345), 0, this.yFromDeg(345)));
    pts.push(new Vector3(this.xFromDeg(330), 0, this.yFromDeg(330)));
    pts.push(new Vector3(this.xFromDeg(315), 0, this.yFromDeg(315)));
    pts.push(new Vector3(this.xFromDeg(300), 0, this.yFromDeg(300)));
    pts.push(new Vector3(this.xFromDeg(285), 0, this.yFromDeg(285)));

    pts.push(new Vector3(this.xFromDeg(270), 0, this.yFromDeg(270)));
    pts.push(new Vector3(this.xFromDeg(255), 0, this.yFromDeg(255)));
    pts.push(new Vector3(this.xFromDeg(240), 0, this.yFromDeg(240)));
    pts.push(new Vector3(this.xFromDeg(225), 0, this.yFromDeg(225)));
    pts.push(new Vector3(this.xFromDeg(210), 0, this.yFromDeg(210)));
    pts.push(new Vector3(this.xFromDeg(195), 0, this.yFromDeg(195)));

    pts.push(new Vector3(this.xFromDeg(180), 0, this.yFromDeg(180)));

    const curve = new CatmullRomCurve3(pts);
    this.path = new CachedCurve(curve, N_POINTS);

    // Creates dummy path
    const lineGeometry = new BufferGeometry().setFromPoints(
      curve.getPoints(N_POINTS)
    );
    const line = new Line(lineGeometry, LINE_MAT);
    PATH_DUMMIES.add(line);

    // Creates dummy points
    dummyPts.push(...curve.points);
    const dPoints = new Points(
      new BufferGeometry().setFromPoints(dummyPts),
      POINTS_MAT
    );
    PATH_DUMMIES.add(dPoints);
  }

  xFromDeg (degrees) {
    return Math.cos(Maths.toRadians(degrees)) * this.radius + this.radius;
  }

  yFromDeg (degrees) {
    return Math.sin(Maths.toRadians(degrees)) * this.radius;
  }
}
