import gsap, { Linear, Power3 } from 'gsap';

import { Ease } from '../_app/cuchillo/utils/Ease';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class Counter {
  current = 1;
  initialized = false;
  max;
  _thumbDom;
  _currentDom;
  _progressDom;
  _totalDom;

  static init (max) {
    this.max = max;

    this._thumbDom = GetBy.selector('#project-counter .__thumb')[0];
    this._currentDom = GetBy.selector('#project-counter .__progress-number')[0];
    this._totalDom = GetBy.selector('#project-counter .__total')[0];
    this._progressDom = GetBy.selector('#project-counter .__progress')[0];
    this._progressDom.style.transforOrigin = 'bottom';
    gsap.set(this._progressDom, {
      scaleY: 0
    });
  }

  static setProgress (progress) {
    this.current = progress;

    if (!this.initialized) return;

    this._currentDom.innerHTML = Counter.setZero(progress);

    gsap.killTweensOf(this._thumbDom);
    gsap.to(this._thumbDom, {
      scaleY: parseInt(progress) / this.max,
      ease: Linear.easeNone,
      duration: .3
    });
  }

  static setZero (n) {
    return n < 10 ? `0${n}` : n;
  }

  static show () {
    gsap.set(this._progressDom, { scaleY: 0 });
    gsap.set(this._currentDom, { y: '100%' });
    gsap.set(this._totalDom, { y: '100%' });

    this._totalDom.innerHTML = '00';
    this._currentDom.innerHTML = '00';

    gsap.killTweensOf(this._progressDom);
    gsap.killTweensOf(this._currentDom);
    gsap.killTweensOf(this._totalDom);

    gsap.to(this._totalDom, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .6,
      delay: .8,
    });

    gsap.to(this._progressDom, {
      scaleY: 1,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .9,
      delay: 1,
    });

    gsap.to(this._currentDom, {
      y: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .6,
      delay: 1.3,
      onComplete: () => {
        this.initialized = true;
        this._currentDom.innerHTML = Counter.setZero(this.current);
      }
    });

    Counter.animateNumber(this._totalDom, this.max, 1, 1.5);
    Counter.animateNumber(this._currentDom, this.current, this.current * .1, 1.9);
  }

  static hide () {
    gsap.killTweensOf(this._progressDom);
    gsap.killTweensOf(this._currentDom);
    gsap.killTweensOf(this._totalDom);

    gsap.to(this._totalDom, {
      y: '100%',
      ease: Power3.easeOut,
      duration: .3
    });
    gsap.to(this._currentDom, {
      y: '100%',
      ease: Power3.easeOut,
      duration: .3
    });
    gsap.to(this._progressDom, {
      scaleY: 0,
      ease: Power3.easeOut,
      duration: .5,
      onComplete: () => {
        this.initialized = false;
      }
    });
  }

  static animateNumber (target, max, duration, delay) {
    const obj = { num: 0 };
    gsap.to(obj, {
      num: max,
      duration: duration < .5 ? .5 : duration,
      delay,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      onUpdate: () => {
        target.innerHTML = Counter.setZero(Math.floor(obj.num))
      }
    });
  }
};
