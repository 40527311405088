import * as THREE from 'three';
import gsap, { Linear, Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { IMAGE_VERTEXT } from '../shaders/vertex';
import { IMAGE_FRAGMENT } from '../shaders/fragment';
import Loader from '../layout/Loader';
import States from './States';
import Timeline, {
  HOVER_ANIMATIONS,
  CANVAS_TEXTURE,
  GEOMETRY
} from './Timeline';

export const IMAGE_SETTINGS = {
  grain: 0
};

export default class Project {
  activeProject = false;
  forceHover = false;
  hover = false;
  forceBw = false;
  forceActive = false;
  bw = false;
  type = 'image';
  element;
  t = 0;
  
  _image;
  _rotation = new THREE.Vector3(0, 0, 0);
  _geometry;
  _texture;
  _material;
  _mesh;
  _aspectRatio;
  _radius;
  _id;
  _scrollItem;
  _callback = null;

  _opacity = {
    [States.detail]: 1,
    [States.scroll]: 1
  };
  _imageScale = {
    [States.detail]: 1,
    [States.scroll]: .85
  };
  _sizes = {
    [States.detail]: new THREE.Vector3(0, 0, 0),
    [States.scroll]: new THREE.Vector3(0, 0, 0)
  };
  _position = {
    [States.detail]: new THREE.Vector3(0, 0, 0),
    [States.scroll]: new THREE.Vector3(0, 0, 0)
  };

  _lookAtTarget = new THREE.Vector3();
  _lookAt = {
    [States.detail]: new THREE.Vector3(0, 0, 500),
    [States.scroll]: new THREE.Vector3(0, 0, 0)
  };
  _lookAtForce = {
    [States.detail]: .6,
    [States.scroll]: .6
  };
 
  _imageScaleForce = .05;
  _scaleForce = .05;
  _positionForce = .7;
  
  _state = States.scroll;

  constructor(element, opts) {
    this.element = element;
    this._radius = opts.radius;
    this._id = opts.id;
    this._scrollItem = opts.scrollItem;
    this.path = opts.path;

    this.setUp();
    this.createMesh();
  }

  get link() {
    return this.element.dataset.link;
  }

  get instance() {
    return this._mesh;
  }

  get id() {
    return this._id;
  }

  get progress() {
    return this._scrollItem.progress;
  }

  get visible() {
    return this._mesh.visible;
  }

  // set opacity(o) {
  //   this._opacity[States.scroll] = o;
  // }

  get active () {
    return this._mesh.visible;
  }

  setUp () {
    // Parsing article content
    this._image = GetBy.selector('img', this.element)[0];
    Loader.loadImage(this._image.getAttribute('src'), (texture) => {
      this.updateTexture(texture);
    });
  }

  getSizes () {
    // Scroll
    const { width, height } = this._image.getBoundingClientRect();
    this._aspectRatio = width / height;
    this._sizes[States.scroll].set(width, height);

    // Detail
    const firstImage = GetBy.id('first-image').getBoundingClientRect();
    
    this._sizes[States.detail].set(firstImage.width, firstImage.height);
  }

  updateTexture(texture) {
    this._texture = texture;
    this._material.uniforms.texture1.value = texture;
  }

  createMesh() {
    this.getSizes();

    const { x, y } = this.calculateResolution();
    const uniforms = {
      color: { type: 'v3', value: new THREE.Color(HOVER_ANIMATIONS.color) },
      texture1: { type: 't', value: CANVAS_TEXTURE.texture },
      aspectRatio: { type: 'f', value: this._aspectRatio },
      hover: { type: 'f', value: HOVER_ANIMATIONS.colorIntensity },
      opacity: { type: 'f', value: 0 }, //this._opacity[this._state]
      imageScale: { type: 'f', value: this._imageScale[this._state] },
      bw: { type: 'f', value: 0 },
      // grain: { type: 'f', value: IMAGE_SETTINGS.grain },
      resolution: {
        type: 'v2',
        value: { x, y }
      }
    };

    this._geometry = GEOMETRY;
    this._material = new THREE.ShaderMaterial({
      uniforms,
      fragmentShader: IMAGE_FRAGMENT,
      vertexShader: IMAGE_VERTEXT,
      transparent: true,
      side: THREE.DoubleSide
    });

    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.scale.set(this._sizes[this._state].x, this._sizes[this._state].y, this._sizes[this._state].z);
    this._mesh.element = this;
  }

  calculateResolution(state = this._state) {
    const { width, height } = this._image.getBoundingClientRect();
    const planeAspect = this._sizes[state].y / this._sizes[state].x;
    let a1;
    let a2;

    if (height / width > planeAspect) {
      const h = (width / height) * planeAspect;
      // Canvas more horizontal than image
      a1 = 1;
      a2 = h;
    } else {
      // Canvas more vertical than image
      a1 = height / width / planeAspect;
      a2 = 1;
    }

    return {
      x: a1,
      y: a2
    };
  }

  loop () {
    // Stuff from settings
    // IMAGE_SETTINGS.grain = IMAGE_SETTINGS.grain + 0.01;
    // this._material.uniforms.grain.value = IMAGE_SETTINGS.grain;
    
    // this._material.uniforms.color.value = new THREE.Color(HOVER_ANIMATIONS.color);
    const hover = this.hover || this.forceHover;
    this._material.uniforms.hover.value = hover ? HOVER_ANIMATIONS.colorIntensity : 0;
    
    const bw = this._state === States.detail || hover || this.activeProject ? 0 : 1;
    this._material.uniforms.bw.value = Maths.lerp(this._material.uniforms.bw.value, bw, .06);

    const op = Timeline.state === States.scroll || this._state === States.detail ? 1 : 0;
    const opacity = Maths.clamp(Maths.lerp(this._material.uniforms.opacity.value, op, .1), 0, 1);
    this._material.uniforms.opacity.value = opacity;

    // Calculates t always
    const t = Maths.clamp(this.t, 0, 1);
    const point = this.path.getPoint(t);
    this._position[States.scroll].copy(point);

    const lookAtPoint = Math.min(t + 0.1, 1);
    this._lookAt[States.scroll] = this.path.getPoint(lookAtPoint);
    
    if (this._state === States.scroll) {
      // Image Scale
      let scale = this._imageScale[States.scroll];
      if (hover) scale = 1;
      const imageScale = Maths.lerp(this._material.uniforms.imageScale.value, scale, this._imageScaleForce);
      this._material.uniforms.imageScale.value = imageScale;

      // Mesh Scale
      const factor = this._state === States.scroll && hover ? 1.15 : 1;
      const scaleX = Maths.lerp(
        this._mesh.scale.x,
        this._sizes[States.scroll].x * factor,
        this._scaleForce
      );
      const scaleY = Maths.lerp(
        this._mesh.scale.y,
        this._sizes[States.scroll].y * factor,
        this._scaleForce
      );
      this._material.uniforms.aspectRatio.value = this._mesh.scale.x / this._mesh.scale.y;
      this._mesh.scale.set(scaleX, scaleY, 1);

      // Position
      // if (this.t >= 0.97 || !this.forceActive) this._mesh.visible = false;
      if (this.t >= 0.97 || !this.forceActive) this._mesh.visible = false;
      else this._mesh.visible = true;
      
      this._mesh.position.lerp(this._position[States.scroll], this._positionForce);
      this._lookAtTarget.lerp(this._lookAt[States.scroll], this._lookAtForce[States.scroll]);
    }

    this._mesh.lookAt(this._lookAtTarget);
  }

  toggleState(state) {
    this.hover = false;

    if (state === States.detail) this._state = state;

    gsap.to(this._mesh.position, {
      x: this._position[state].x,
      y: this._position[state].y,
      z: this._position[state].z,
      duration: 1,
      ease: Power2.easeOut,
      onUpdate: () => {
        this._material.uniforms.resolution.value = this.calculateResolution(state);
      },
      onComplete: () => {
        this._material.uniforms.resolution.value = this.calculateResolution(state);
        if (state === States.scroll) this._state = state;
      }
    });
    gsap.to(this._mesh.scale, {
      x: this._sizes[state].x,
      y: this._sizes[state].y,
      z: this._sizes[state].z,
      duration: .6,
      ease: Power2.easeOut
    });
    gsap.to(this._material.uniforms.imageScale, {
      value: this._imageScale[state],
      duration: .6,
      ease: Power2.easeOut
    });

    gsap.to(this._lookAtTarget, {
      x: this._lookAt[state].x,
      y: this._lookAt[state].y,
      z: this._lookAt[state].z,
      duration: 1,
      ease: Power2.easeOut
    });
  }

  domPositionTo3D(__x, __y) {
    const x = -1 * Metrics.WIDTH * 0.5 + __x;
    const y = Metrics.HEIGHT * 0.5 - __y;

    return {
      x,
      y,
      z: 0
    };
  }

  resize() {
    this.getSizes();
  }
}
