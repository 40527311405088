export const IMAGE_VERTEXT = `
    #define PI 3.1415926538

    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;
