import gsap, { Power3 } from 'gsap';

import { Ease } from '../_app/cuchillo/utils/Ease';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Basics } from '../_app/cuchillo/core/Basics';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Timeline from '../3D/Timeline';

export default class Thumbnails {
  _wrapper;
  _items;
  _progress = 0;
  _x = 0;
  _width;

  constructor() {
    this._container = GetBy.id('projects-thumbnails');
    this._wrapper = GetBy.selector('#projects-thumbnails .__wrapper')[0];
    this._items = Array.from(GetBy.selector('li', this._wrapper));

    const { width } = this._wrapper.getBoundingClientRect();
    this._width = width - Metrics.WIDTH;

    this._enter = (e) => {
      this.onMouseEnter(e.target);
    };
    this._leave = (e) => {
      this.onMouseLeave(e.target);
    };
    this._click = (e) => {
      this.onClick(e.target);
    };

    this._items.map((item) => {
      item.addEventListener(Basics.clickEvent, this._click);
      item.addEventListener(Basics.mouseOver, this._enter);
      item.addEventListener(Basics.mouseOut, this._leave);
    });
  }

  onMouseEnter (target) {
    Timeline.hoverProject(target.dataset.target);
    this._wrapper.classList.add('hover');
  }

  onMouseLeave (target) {
    Timeline.hoverProject(target.dataset.target, false);
    this._wrapper.classList.remove('hover');
  }

  onClick (target) {
    TimelineScroll.gotoAnchor(target.dataset.target);
  }

  clearHover () {
    this._items.map((item) => {
      item.classList.remove('no-hover');
      item.classList.remove('hover');
    });
  }

  hoverThumbnail (target) {
    this._items.map((item) => {
      if (item.id === target) item.classList.add('hover');
      else item.classList.add('no-hover');
    });
  }

  loop () {
    this._progress = Maths.lerp(
      this._progress,
      TimelineScroll.engine.progress,
      0.1
    );
    this._x = Maths.lerp(0, this._width, this._progress);

    this._wrapper.style[CSS.transform] =
      'translate3d(' + -this._x + 'px, 0, 1px)';
  }

  resize () {
    const { width } = this._wrapper.getBoundingClientRect();
    this._width = width - window.innerWidth;
  }

  dispose () { }

  show () {
    const height = getComputedStyle(this._container).getPropertyValue('--height');

    gsap.killTweensOf(this._container);
    gsap.set(this._container, { height: 0 });
    gsap.to(this._container, {
      height,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: .8,
      delay: .5,
      onComplete: () => {
        this._container.style.pointerEvents = 'initial';
      }
    });
  }

  hide () {
    this._container.style.pointerEvents = 'none';
    gsap.killTweensOf(this._container);
    gsap.to(this._container, {
      height: 0,
      ease: Power3.easeOut,
      duration: .5
    });
  }
}
