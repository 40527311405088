import gsap from 'gsap';

import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Timeline from '../3D/Timeline';
import Counter from '../components/Counter';
import Loader from '../layout/Loader';
import { GetBy } from '../_app/cuchillo/core/Element';
import States from '../3D/States';
import Titles from '../components/Titles';

export default class Default extends Page {
  constructor() {
    super();
  }

  show__effect () {
    super.show__effect();
    this.afterShow();
  }

  beforeShow () {
    if (this.isFirstTime) {
      Timeline.init();
      Timeline.start();
      TimelineScroll.stop();

      
      Loader.start(() => {
        Timeline.thumbnails.hide();
        Counter.hide();
        Timeline.animate();
        gsap.to(GetBy.id('scroll-down'), { opacity: 1, duration: .5, delay: 3 });
      });
    }
  }

  afterShow () {
    super.afterShow();
    TimelineScroll.start();
    Timeline.thumbnails.show();
    Timeline.state = States.scroll;
    Counter.show();
  }

  beforeHide () {
    super.beforeHide();
    gsap.to(GetBy.id('scroll-down'), { opacity: 0, duration: .5 });
  }

  afterHide () {
    super.afterHide();

    Timeline.thumbnails.hide();
    Counter.hide();
    Titles.resetTitles();
  }
}

ControllerPage._addPage('default', Default);
