import gsap, { Power0, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Ease } from '../_app/cuchillo/utils/Ease';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Page from '../_app/cuchillo/pages/Page';

import Timeline from '../3D/Timeline';
import Header from '../layout/Header';
import Loader from '../layout/Loader';

const allowedLangs = ['ca', 'es', 'en'];

export default class Info extends Page {
  lang = 'ca';
  langs;
  footer;
  blocks;

  constructor() {
    super();

    this.langs = Array.from(GetBy.selector('#lang-selector .link-underline'));
    this.blocks = Array.from(GetBy.selector('.__lang-block', this.container));
    this.footer = GetBy.class('footer', this.container)[0];

    const userLang = navigator.language || navigator.userLanguage;
    if (allowedLangs.indexOf(userLang) > 0) this.lang = userLang;

    this.setAnimations();

    this.langs.map(lang => {
      lang.addEventListener(Basics.clickEvent, () => {
        this.hideCurrentLang();
        this.lang = lang.dataset.lang;
        this.selectLang(true);
      });
    });
  }

  show__effect () {
    super.show__effect();

    gsap.set(this.container, { alpha: 0 });
    gsap.set(this.langs, { alpha: 0 });
    gsap.set(this.footer, { alpha: 0 });

    gsap.to(this.container, {
      alpha: 1,
      duration: .4,
      delay: .5,
      ease: Power0.easeOut
    });
    gsap.to([...this.langs, this.footer], {
      alpha: 1,
      duration: .3,
      delay: .8,
      ease: Power0.easeOut,
      onComplete: () => {
        this.afterShow();
      }
    });
  }

  beforeShow () {
    Header.activate(true);
    Header.toggleClass();

    if (this.isFirstTime) {
      Timeline.init();
      Timeline.start();
      Timeline.activateProjects();
    }

    Timeline.pause();

    if (!isMobile) {
        Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isMobile, multiplicator: 1 });
        Scroll.start();
    }
  }
  
  afterShow () {
    super.afterShow();

    const cb = () => {
      Header.show();
      this.selectLang(false);
    }

    if (this.isFirstTime) Loader.start(cb);
    else cb();
  }

  hide__effect () {
    if (!isMobile) Scroll.hide();

    gsap.to(this.container, {
      alpha: 0,
      delay: .6,
      duration: .4,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
    gsap.to([...this.langs, this.footer], {
      alpha: 0,
      duration: .3,
      delay: .2,
      ease: Power0.easeOut
    });
  }
  
  beforeHide () {
    Header.activate(false);
    Header.toggleClass(true);
    this.hideCurrentLang();
    
    super.beforeHide();
  }
  
  afterHide() {
    if (!isMobile) Scroll.dispose();

    Timeline.play();
    
    super.afterHide();
  }

    //LOOP
  loop() {
    if(this._isActive) {
      super.loop();
    }
  }

  setAnimations () {
    this.blocks.map(block => {
      const title = GetBy.selector('h2 > div', block);
      const rest = GetBy.class('__animate', block);

      const lines = new SplitText(title, {
        type: 'lines',
        linesClass: 'line-child',
      }).lines;
      lines.map(l => {
        l.style.transformOrigin = 'top left';
      })
      new SplitText(rest, {
        type: 'lines',
        linesClass: 'line-child',
      });
      new SplitText(rest, {
        type: 'lines',
        linesClass: 'line-parent',
      });
    });
  }

  selectLang (hide = true) {
    let delay = .1;

    this.langs.filter(lang => lang.dataset.lang === this.lang)[0].classList.add('--active');

    if (hide) delay = .3;

    const block = this.blocks.filter(block => block.dataset.lang === this.lang)[0];
    const title = GetBy.selector('h2 .line-child', block);
    const rest = GetBy.selector('.__animate .line-child', block);

    gsap.set(title, {
      y: isMobile ? '210%' : '105%',
      rotateZ: '10deg'
    });
    gsap.set(rest, { y: '105%' });

    gsap.delayedCall(delay, () => {
      block.classList.add('--active');
    });

    gsap.to(title, {
      y: 0,
      rotateZ: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1.3,
      delay: delay
    });
    gsap.to(rest, {
      y: 0,
      rotateZ: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT,
      duration: 1.1,
      delay: delay + .3
    });
  }

  hideCurrentLang() {
    this.langs.filter(lang => lang.dataset.lang === this.lang)[0].classList.remove('--active');
    const blocks = this.blocks.filter(block => block.classList.contains('--active'));

    blocks.map(block => {
      const title = GetBy.selector('h2 .line-child', block);
      const rest = GetBy.selector('.__animate .line-child', block);

      gsap.to(title, {
        y: isMobile ? '210%' : '105%',
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        duration: .5,
        onComplete: () => {
          block.classList.remove('--active');
        }
      });
      gsap.to(rest, {
        y: '105%',
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        duration: .5,
        onComplete: () => {
          block.classList.remove('--active');
        }
      });
    });
  }
}

ControllerPage._addPage('info', Info);
