import { Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import _Header from '../_app/cuchillo/layout/Header';

export default class Header extends _Header {
  isActive = false;
  _logo;

  static options = {
    show: {
      duration: 0.8,
      delay: 0,
      ease: Power2.easeOut
    },
    hide: {
      duration: 0.8,
      delay: 0,
      ease: Power2.easeIn
    }
  };
  
  static init () {
    super.init();
    this._logo = GetBy.class('logo', this.container)[0];
  }

  static toggleClass (force = false) {
    this.container.classList[force || this.isActive ? 'remove' : 'add']('--active');
    this.isActive = !this.isActive;
  }

  static activate (active = false) {
    if (active) {
      this._logo.removeAttribute('aria-current');
    } else {
      this._logo.setAttribute('aria-current', 'page');
    }
  }
}
