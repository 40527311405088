import { GetBy } from '../_app/cuchillo/core/Element';
import VTimelineScroll_Item from './VTimelineScroll_Item';
import { TimelineScroll } from './TimelineScroll';
import Counter from '../components/Counter';

class ScrollItem__Starter extends VTimelineScroll_Item {
  _counter;
  _index;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._counter = GetBy.selector('#project-counter .__progress-number')[0];
    this._index = this.item.dataset.index;

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => {
      if (this.progress === 0.5) {
        Counter.setProgress(this._index);
      }
    };
  }

  setZero (n) {
    return n < 10 ? `0${n}` : n;
  }
  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose () {
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h);
  }
}

TimelineScroll._registerClass('scroll-starter', ScrollItem__Starter);
