import VTimelineScroll_Item from './VTimelineScroll_Item';
import { TimelineScroll } from './TimelineScroll';
import Timeline, { SCROLL_ANIMATIONS } from '../3D/Timeline';
import Counter from '../components/Counter';
import Titles from '../components/Titles';

class ScrollItem__Project extends VTimelineScroll_Item {
  _index;
  _titleID;
  _isVisible;
  _id;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    Timeline.registerProject(this);

    this._isVisible = false;
    
    this._index = this.item.dataset.index;
    this._titleID = this.item.dataset.title;
    this._id = this.item.id;

    this.onShow = () => {};
    this.onHide = () => {
      // Timeline.activeProject(this._id, false);
      Titles.hideTitle(this._titleID);
      this._isVisible = false;
    };

    this.onMove = () => {
      if (this.progress <= SCROLL_ANIMATIONS.showTitle && this.progress >= SCROLL_ANIMATIONS.hideTitle && !this._isVisible) {
        // Timeline.activeProject(this._id);
        Titles.showTitle(this._titleID);
        Counter.setProgress(this._index);
        this._isVisible = true;
      } else if ((this.progress < SCROLL_ANIMATIONS.hideTitle || this.progress > SCROLL_ANIMATIONS.showTitle) && this._isVisible) {
        this.onHide();
      }
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose () {
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h);
  }
}

TimelineScroll._registerClass('scroll-project', ScrollItem__Project);
