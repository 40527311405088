import { gsap, Power2 } from 'gsap';

import { Scroll } from './_app/cuchillo/scroll/Scroll';
// import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
// import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
// import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
// import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
// import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { Keyboard } from './_app/cuchillo/core/Keyboard';
import {
  Basics,
  isDebug,
  // isMobile,
  isTouch
} from './_app/cuchillo/core/Basics';
import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Statics } from './_app/cuchillo/utils/Statics';
import { GetBy } from './_app/cuchillo/core/Element';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';
import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import { Metrics } from './_app/cuchillo/core/Metrics';
// import Legal from './pages/Legal';
import Page from './_app/cuchillo/pages/Page';
import NotFound from './pages/NotFound';
import Default from './pages/Default';
import Project from './pages/Project';
import Info from './pages/Info';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Cursor from './_app/cuchillo/cursor/Cursor';

import Wrap from './layout/Wrap';
// import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import BG from './_app/cuchillo/layout/Background';
import { Colors } from './Colors';
import Cookies from './windows/Cookies';
import Loader from './layout/Loader';
import Header from './layout/Header';
import Timeline from './3D/Timeline';

import { ScrollItem__Project } from './scroll/ScrollItem__Project';
import { ScrollItem__Starter } from './scroll/ScrollItem__Starter';
import { ScrollItem__ProjectImage } from './scroll/ScrollItem__ProjectImage';

export default class Main {
  static scrollbar;
  static stats;

  static init () {
    Basics.id = 'w11p_v005'; // ID para cookies

    Header.init();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }); // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(Colors); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    // InterfaceCanvas.init(); //

    Loader.init();

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    // LoaderController.update = (progress) => {
    //   console.log('Progress...', progress);
    // };
    LoaderController.onComplete = () => Main.setup();
    LoaderController.init();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => {
        Main.loopDebug();
      });
    } else {
      gsap.ticker.add(() => {
        Main.loop();
      });
    }

    // Scroll indicator
    // const scrollContainer = GetBy.id('projects-items');
    const _scroll = () => {
      const indicator = GetBy.id('scroll-down');
      gsap.to(indicator, {
        opacity: 0,
        duration: .4,
        delay: .2,
        ease: Power2.easeOut,
        onComplete: () => {
          indicator.remove()
          window.removeEventListener('touchstart', _scroll);
          window.removeEventListener('wheel', _scroll);
        }
      });
    }

    window.addEventListener('touchstart', _scroll);
    window.addEventListener('wheel', _scroll);
  }

  static setup () {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static setupEvents () {
    // EventDispatcher.addEventListener(Page.ON_SHOW, () => {
    //   Cursor.start();
    // });
    // EventDispatcher.addEventListener(Page.ON_HIDE, () => {
    //   Cursor.hide();
    // });
    EventDispatcher.addEventListener(Win.ON_HIDE, () => {
      Scroll.setEnabled(true);
    });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => {
      Scroll.setEnabled(false);
    });
  }

  static resize () {
    Header.resize();
    Timeline.resize();
    ControllerPage.resize();
    // InterfaceCanvas.resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop () {
    if (Scroll.isScrolling) Scroll.loop();

    Timeline.loop();
    ControllerPage.loop();
    // InterfaceCanvas.loop();

    // if (!isTouch) Cursor.loop();
  }

  static loopDebug () {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static setWorker () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (
  document.attachEvent
    ? document.readyState === 'complete'
    : document.readyState !== 'loading'
) {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
